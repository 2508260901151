import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import AdminPage from '../../../components/adminPage';
import { useAdminUser } from '../../../hooks/adminUser';
import PageLoading from '../../../components/pageLoading';
import ErrorHeading from '../../../components/errorHeading';
import { getResourceText } from '../../../lib/language';
import { useApi } from '../../../hooks/api';
import { formatDateWithTime } from '../../../lib/date';
import Icon, { GlyphNames } from '../../../components/icon';


const AdminSubscriptions = () => {
  useAdminUser();
  const [subscriptions, setSubscriptions] = useState();
  const [error, setError] = useState(false);

  const { admin: { getSubscriptions } } = useApi();

  useEffect(() => {
    const getData = async () => {
      const { status, json } = await getSubscriptions();

      if (status === 200) {
        setSubscriptions([...json].sort(({ endDate: a }, { endDate: b }) => new Date(a).getTime() - new Date(b).getTime()));
      } else {
        setError(true);
      }
    };
    getData().catch(() => setError(true));
  }, [getSubscriptions]);

  return (
    <AdminPage withBack={false}>
      <div className="admin-subscriptions-container">
        <div className="admin-subscriptions-container__main-heading-container">
          <h1 className="admin-subscriptions-container__main-heading">Subscriptions</h1>
          <Link to="/admin/subscriptions/create" className="btn admin-btn admin-subscriptions-main-heading-container__add-new" aria-label="Add new subscription"
                title="Add new subscription">
            <Icon glyphName={GlyphNames.plus}/>
          </Link>
        </div>
        {!error && !subscriptions ? <PageLoading/> : null}
        {error ? <ErrorHeading text="Error getting subscriptions"/> : null}
        {subscriptions && !subscriptions.length ? <h2 className="admin-subscriptions-container__sub-heading">{getResourceText('noSubscriptionsFound')}</h2> : null}
        {!error && subscriptions ? (
          <div className="admin-subscriptions-list-container">
            {subscriptions.length ? (
              <table className="admin-subscriptions-table">
                <thead>
                <tr>
                  <th>{getResourceText('organisationTableHeading')}</th>
                  <th>{getResourceText('resourcePackTableHeading')}</th>
                  <th>{getResourceText('startDateTableHeading')}</th>
                  <th>{getResourceText('expiresDateTableHeading')}</th>
                  <th>{getResourceText('actionsTableHeading')}</th>
                </tr>
                </thead>
                <tbody>
                {subscriptions.map(({ organisationId, organisationName, subscriptionId, resourcePackName, startDate, endDate }) =>
                  <tr key={subscriptionId}>
                    <td>{organisationName}</td>
                    <td className="admin-subscriptions-table__center">{resourcePackName}</td>
                    <td className="admin-subscriptions-table__center">{formatDateWithTime(startDate)}</td>
                    <td className="admin-subscriptions-table__center">{formatDateWithTime(endDate)}</td>
                    <td>
                      <Link to={`/admin/organisations/${organisationId}/subscriptions/${subscriptionId}/edit`}>Edit</Link>
                    </td>
                  </tr>)}
                </tbody>
              </table>
            ) : null}
          </div>
        ) : null}
      </div>
    </AdminPage>
  );
};

export default AdminSubscriptions;